import React, { Fragment, useEffect, useState } from 'react'
import { toast, Bounce } from 'react-toastify'
import { GetTokenApp, LoginUsuario } from '../../../Service/ApiService'
import { ContainerForm, Input, Button, Link, Title } from '../styles'
import { FooterLogin } from './styles'
import { InputCheck, Loading } from '../../../Components'
import { getUserLogState, putToken, putUser, putUserLogState } from '../../../Reducers/storageApp'
import LoadAcessos from '../../../Components/LoadAcessos'

export default function Element() {
  const [values, setValues] = useState({ email: '', senha: '', lembrarSenha: false })
  const [isLoading, setIsLoading] = useState(false)
  const [TokenApp, setTokenApp] = useState()
  const [isLoadAcessos, setIsLoadAcessos] = useState({ show: false, dest: '' })

  async function getTokenApp() {
    var token = TokenApp
    if (!TokenApp) {
      var response = await GetTokenApp()
      if (response?.token) {
        token = response.token
        setTokenApp(response.token)
      } else {
        toast('Ocorreu um problema ao gerar um token para o portal', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    }
    return token
  }

  async function onLogin(email = values.email, senha = values.senha) {
    var token = await getTokenApp()
    let stringToCodify = email + ':' + senha
    let encoded = btoa(stringToCodify)

    const response = await LoginUsuario(token, encoded)
    if (!response) {
      toast('Ops, ocorreu um problema na verificação da sua conta', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return undefined
    }
    if (response.result) return response
    else {
      toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return undefined
    }
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    setTimeout(function () {
      getLogState()
    }, 1200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getLogState() {
    var userLogState = getUserLogState()
    if (userLogState) {
      try {
        setIsLoading(true)

        for (let i = 0; i < userLogState.length - 1; i++) {
          if (userLogState.substring(i, i + 1) === ':') {
            const result = await onLogin(userLogState.substring(0, i), userLogState.substring(i + 1, userLogState.length))
            if (result) {
              await onSaveState(result)

              setIsLoadAcessos({ show: true, dest: '' })
            } else setIsLoading(false)
          }
        }
      } catch {
        setIsLoading(false)
      }
    }
  }

  async function onSubmit(ev) {
    ev.preventDefault()

    if (values.email.length < 6) {
      toast('Informe um e-mail valido', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }
    if (values.senha.length < 6) {
      toast('A senha é inválida', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      return
    }

    try {
      setIsLoading(true)
      const result = await onLogin()

      if (result) {
        await onSaveState(result)
        if (values.lembrarSenha) putUserLogState(values.email + ':' + values.senha)

        setIsLoadAcessos({ show: true, dest: '' })
      } else setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  async function onSaveState(result) {
    putToken(result.token, result.expiration)
    putUser({
      id: result.usuario.usuarioId,
      name: result.usuario.nome,
      perfil: result.usuario.perfil,
      participanteId: result.usuario.participanteId,
      filialId: result.usuario.filialId,
    })
  }

  async function onLoadAcessos() {
    setTimeout(function () {
      window.location.href = '/#/Home'
    }, 500)
  }

  return (
    <Fragment>
      <Loading isVisible={isLoading} />
      {isLoadAcessos.show && <LoadAcessos onChange={onLoadAcessos} />}

      <ContainerForm onSubmit={onSubmit}>
        <Title>Informe seus dados de acesso</Title>
        <Input autoFocus type="email" name="email" placeholder="E-mail" onChange={onChange} value={values.email} />
        <Input type="password" name="senha" placeholder="Senha" onChange={onChange} value={values.senha} />

        <Button type="submit">entrar</Button>

        <FooterLogin>
          <InputCheck value={values.lembrarSenha} label="Lembrar-me" onChange={onChange} name="lembrarSenha" styleLabel={{ fontSize: '0.95rem' }} />
          <Link href="/#/login/recuperar">Esqueci minha senha</Link>
        </FooterLogin>
      </ContainerForm>
    </Fragment>
  )
}
