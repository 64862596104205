import React from 'react'
import { Input } from 'reactstrap'
import styled from 'styled-components'

export default function InputComponent({ value, name, label, onChange, isChecked = false }) {
  const onSelect = (ev) => {
    if (onChange) {
      onChange({ target: { value: value, name: name } })
    }
  }

  return (
    <Container>
      <RadioContainer className="RadioContainer">
        <SelectBox type="radio" defaultChecked={isChecked} onClick={onSelect} name={name} />
        <span className="RadioCheckmark"></span>
      </RadioContainer>
      <Label>{label}</Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const RadioContainer = styled.label`
  margin-right: 5px;
`

const SelectBox = styled(Input)``
const Label = styled.label`
  color: ${(props) => props.isHighlight && 'var(--primary)'};
  font-size: ${(props) => props.isHighlight && '1.5rem'};
  font-weight: ${(props) => props.isHighlight && '600'};
`
