import { React } from 'react'
import { CardCopy } from './styles'
const version = process.env.REACT_APP_VERSION

export default function Footer() {
  return (
    <>
      <div className="app-footer">
        <CardCopy className="app-footer__inner">
          <div className="app-footer-left">
            <span>
              <strong>© Farmácia M2M </strong> | Version: {version}
            </span>
          </div>
          <div className="app-footer-right">
            <span>
              Designed and Developed by
              <strong>
                <a target="_blank" rel="noreferrer" href="https://bitys.com.br">
                  {' '}
                  Bitys
                </a>
              </strong>
            </span>
          </div>
        </CardCopy>
      </div>
    </>
  )
}
