import styled from 'styled-components'

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin: 0;
    border-left: none;
    max-width: 100% !important;
    width: 100% !important;
    align-items: flex-start;
  }
`

export const TotalText = styled.h3`
  margin: 0;
  font-size: 20px;
  color: #684fa9;
  font-weight: 500;
`

export const ItemTotal = styled.div`
  width: 100%;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    max-width: 100% !important;
    width: 100% !important;
    align-items: flex-start;
  }
`

export const TextTotal = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 700;
  color: #684fa9;

  span {
    font-weight: 100;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    height: 60px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
`
export const ContinueButton = styled.button`
  padding: 10px 20px;
  margin-left: auto;
  font-size: 16px;
  color: white;
  background-color: #27ae60;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const BoxDtEntrega = styled.div`
  display: flex;
  flex-direction: column;

  span:nth-child(1) {
    font-size: 16px;
    color: rgb(14, 178, 83);
    font-weight: 600;
  }
  span:nth-child(2) {
    font-size: 12px;
    color: rgb(153, 153, 153);
  }
`
