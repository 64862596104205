import React, { useEffect, useState } from 'react'
import Footer from './AppFooter'
import { Bounce, LoadingCard, toast, useParams } from '../../../Components'
import { BoxLoading, Wrapper } from './styles'
import CardHeader from './CardHeader'
import Checkout from './Checkout'
import Tracking from './Tracking'
import { OrcamentoGetByKey } from '../../../Service/ApiService'

export default function FormCadastro() {
  const { id } = useParams()
  const [step, setStep] = useState(1)
  const [values, setValues] = useState()

  async function loadOrcamento() {
    let response = await OrcamentoGetByKey(id)

    if (response) {
      let _values = { ...response, ...response.orcamento, pedidos: response.pedidos, itensAdicionais: response.itensAdicionais }
      setValues(_values)
      if (_values.situacao == 0) setStep(2)
      else setStep(3)
    } else toast('Ocorreu um problema ao realizar a consulta', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
  }

  useEffect(() => {
    if (id) loadOrcamento()
  }, [id])

  return (
    <Wrapper>
      <CardHeader />
      {
        {
          1: (
            <BoxLoading>
              <LoadingCard isVisible={true} placeholder="Aguarde ..." />
            </BoxLoading>
          ),
          2: <Checkout value={values} />,
          3: <Tracking value={values} />,
        }[step]
      }

      <Footer />
    </Wrapper>
  )
}
