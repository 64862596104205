import styled from 'styled-components'

export const OptMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  padding-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    max-width: 100% !important;
    width: 100% !important;
    overflow: scroll;
    padding: 10px 0px;
  }
`

export const PayItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 33%;
  font-size: 1rem;
  padding: 20px 10px;
  border: 1px solid ${(props) => (props.selected ? '#684fa9a8' : '#684fa9a8')};
  background-color: ${(props) => (props.selected ? '#684fa9' : '#f1f1f1')};
  color: ${(props) => (props.selected ? '#fff' : '#4d4d4d')};
  opacity: ${(props) => (props.selected ? '1' : '0.6')};
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 0.3s, border-color 0.5s;

  &:hover {
    transition: 0.5s;
    background-color: #684fa9a8;
    color: #fff;
  }

  span {
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px 4px;
    font-size: 13px;

    span > {
      font-size: 16px;
    }
  }
`
