import styled from 'styled-components'

export const CartItems = styled.div`
  display: flex;
  flex-direction: column;
`
export const LblDescricao = styled.div`
  font-size: 14px;
  padding-left: 5px;
  margin-right: 10px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const LblQtde = styled.div`
  width: 70px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const BoxInfos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 5px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    align-items: center;
  }
`

export const LblPreco = styled.span`
  font-size: 0.8rem;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`
export const BtnDel = styled.button`
  margin: 0px 8px;
`

///////////////////////////

export const RowAdic = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  width: 100%;
  padding: 4px;
  margin-bottom: 6px;
  background: linear-gradient(40deg, #fff, #684fa92e, #00ad72);
  border-radius: 4px;
`
export const BoxAdic = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`
export const RowAdicDesc = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const BoxBtnAdic = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;

  svg {
    cursor: pointer;
    margin: 0 5px;
    font-size: 2rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    transition: background-color 0.15s ease-in-out;
    :hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  @media (max-width: 768px) {
    margin: 5px 0 0 auto;
  }
`
export const LblQtdeAdic = styled.span`
  font-size: 16px;
  padding-inline: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  color: #684fa9;
`

export const LblVazio = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: red;
  padding: 10px 0;
`
