import axios from 'axios'
import { getToken } from '../Reducers/storageApp'

let token = getToken()
const api = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  },
  baseURL: process.env.REACT_APP_API,
})

api.interceptors.request.use(
  (config) => {
    const access_token = getToken()
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`
      return config
    } else return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// api.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   async function (error) {
//     //const token = getToken()
//     console.log('interceptors error', error)
//     // if (error.response.status === 401 && access_token) {
//     //   const response = await refreshToken(error)
//     //   return response
//     // }
//     return Promise.reject(error)
//   }
// )

export default api
