import styled from 'styled-components'
export const Wrapper = styled.div`
  padding-top: 12px;
  height: calc(100vh - 100px);
`

export const Main = styled.div`
  background-color: rgb(211, 218, 221);
  padding: 16px;
  border-radius: 10px;
  margin: 0 auto 10px auto;
  max-width: 998px;

  @media (max-width: 576px) {
    margin: 0 5px 10px 5px;
    padding: 10px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 10px;
  > div {
    display: flex;
    flex-direction: column;
  }
`

export const CardMain = styled.div`
  background: rgb(255, 255, 255);
  padding: 16px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const MainTimeLine = styled.div`
  width: 40%;

  @media (max-width: 576px) {
    width: 100%;
  }
`
export const MainContent = styled.div`
  width: 60%;
  padding: 0 10px;
  @media (max-width: 576px) {
    width: 100%;
  }
`
