import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`
export const BoxLoading = styled.div`
  height: calc(100vh - 100px);
`

export const BoxContent = styled.div`
  max-width: 992px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  flex-direction: row;
`

export const FormContainer = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }
`
/* VARIAVEIS GLOBAIS PARA COMPONENTES SALES  */

export const BoxInput = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-left: 1px solid grey;
  padding-inline: 10px;
`
export const Input = styled.input`
  display: block;
  font-size: 1rem;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  background: transparent;
  border: none;
  border-bottom: 1px solid grey;
  &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
  }
`
export const ButtonBack = styled.button`
  color: grey;
  background: transparent;
  border: none;
  border-radius: 4px;
  padding: 10px 0px;
  width: auto;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  transition: 1s;
  &:hover {
    transition: 0.5s;
    opacity: 0.5;
  }
  @media (max-width: 768px) {
    padding: 10px 0px;
  }
`
export const TextEtapa = styled.p`
  width: 100%;
  font-weight: 700;
  font-size: 1rem;
  padding: 10px 0px;
  @media (max-width: 768px) {
    max-width: 100% !important;
    width: 100% !important;
    align-items: center;
    padding: 10px 0px;
  }
`

export const Title = styled.h3`
  font-size: 40px;
  font-weight: 600;
  padding: 10px 0px;
  @media (max-width: 768px) {
    max-width: 100% !important;
    width: 100% !important;
    align-items: center;
    padding: 10px 0px;
  }
`

export const TextInfo = styled.p`
  font-weight: 200;
  font-size: 1rem;
  padding: 10px 0px;
  @media (max-width: 768px) {
    max-width: 100% !important;
    width: 100% !important;
    align-items: center;
    padding: 10px 0px;
  }
`
export const Label = styled.label`
  font-size: 0.7rem;
  color: #333;
  margin-bottom: 5px;
`
