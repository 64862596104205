import { BoxContent } from '../styles'
import { Wrapper, BoxLogo, ImgLogo } from './styles'

export default function CardHeader() {
  return (
    <Wrapper>
      <BoxContent>
        <BoxLogo>
          <ImgLogo />
        </BoxLogo>
      </BoxContent>
    </Wrapper>
  )
}
