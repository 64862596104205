import { MdAdd, MdRemove } from 'react-icons/md'
import { Card, CardBody } from 'reactstrap'
import { BtnNext, ItemHeader } from '../styles'
import { CartItems, BoxInfos, RowAdic, LblDescricao, LblPreco, LblQtdeAdic, BoxBtnAdic, BtnDel, BoxAdic, RowAdicDesc, LblVazio } from './styles'
import { FaTrashAlt } from 'react-icons/fa'
import ItensSugeridos from './Sugestao'
import { MaskReal } from '../../../../../helpers/util'
import { GrMapLocation } from 'react-icons/gr'
import { useEffect, useState } from 'react'
import ItemOrdem from './ItemOrdem'

export default function CardResumo({ value, change, step, onUpSugestao, sugeridos, onDelItem }) {
  const [pedItens, setPedItens] = useState([])

  useEffect(() => {
    if (value) getItens()
  }, [value])

  function getItens() {
    let result = []
    value.pedidos.forEach((ped) => {
      if (!ped.isExcluido) {
        var pedValue = value?.itens.filter((f) => f.pedidoId == ped.pedidoId)
        result.push(pedValue[0])
      }
    })
    setPedItens(result)
  }

  return (
    <>
      <Card className="main-card mb-2" style={{ width: '100%' }}>
        <ItemHeader>Itens do pedido</ItemHeader>
        <CardBody>
          {pedItens?.length > 0 || value.itensAdicionais?.length > 0 ? (
            <CartItems>
              {pedItens?.map((d, index) => (
                <ItemOrdem key={`p_${d.pedidoId}`} index={index} value={d} onDelItem={onDelItem} />
              ))}

              {value.itensAdicionais?.map((d) => (
                <RowAdic key={d.id}>
                  <BoxAdic>
                    <RowAdicDesc>
                      <LblDescricao>{d.name}</LblDescricao>

                      <BoxBtnAdic>
                        <MdRemove onClick={() => onUpSugestao({ value: d, op: 'Dec' })} style={{ color: '#fff' }} />
                        <LblQtdeAdic>{d.qtde}</LblQtdeAdic>
                        <MdAdd onClick={() => onUpSugestao({ value: d, op: 'Add' })} style={{ color: '#fff' }} />
                      </BoxBtnAdic>
                    </RowAdicDesc>
                  </BoxAdic>

                  <BoxInfos>
                    <LblPreco>{MaskReal(d.price)}</LblPreco>

                    <BtnDel className="btn btn-danger btn-sm" onClick={() => onUpSugestao({ value: d, op: 'Del' })}>
                      <FaTrashAlt />
                    </BtnDel>
                  </BoxInfos>
                </RowAdic>
              ))}
            </CartItems>
          ) : (
            <LblVazio>Carrinho de compras vazio!</LblVazio>
          )}
          <BtnNext showlarge={true} onClick={() => change({ value, stepId: 1 })}>
            <GrMapLocation size={18} className="mr-2" /> <span>Ir para dados de entrega</span>
          </BtnNext>
        </CardBody>
      </Card>

      {sugeridos?.length > 0 && <ItensSugeridos records={sugeridos} onAdd={(ev) => onUpSugestao(ev)} />}
    </>
  )
}
