import React from 'react'
import { Card, ProductImage, ProductName, ProductPrice, OldPrice, BuyButton } from './styles'
import { PiPlusCircle } from 'react-icons/pi'
import { BsCartPlus } from 'react-icons/bs'

export default function Item({ value, onAdd }) {
  const formattedPrice = parseFloat(value.price).toFixed(2)

  return (
    <Card>
      <ProductImage src={value.image} alt={value.name} />
      <ProductName>{value.name}</ProductName>
      {formattedPrice && value.price !== value.oldPrice && value.price < value.oldPrice && <OldPrice>R${formattedPrice}</OldPrice>}

      <ProductPrice>
        <span>R${formattedPrice}</span>

        <BuyButton onClick={() => onAdd(value)}>
          <BsCartPlus size={24} />
        </BuyButton>
      </ProductPrice>
    </Card>
  )
}
