import React, { useState } from 'react'
import { Label, Tooltip } from 'reactstrap'

export default function ({ id, placement, children, content, text, classColor }) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen(!isOpen)
  }

  return (
    <>
      {children ? (
        children
      ) : (
        <Label style={{ height: '18px' }} className={`badge badge-pill badge-secondary badge-${classColor ? classColor : 'secondary'} `} color="primary" id={'TooltipLight-' + id}>
          {text}
        </Label>
      )}

      <Tooltip className="tooltip-light" placement={placement} isOpen={isOpen} target={'TooltipLight-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </>
  )
}
