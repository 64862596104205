import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #5e50a6;
  padding: 10px 0;
  text-transform: uppercase;
`
export const Main = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

//Item
export const ContainerCards = styled.div`
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  transition: all 0.2s;
`
export const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0 6px 6px;
  text-align: center;
  width: 100%;
  margin-right: 10px;

  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 1px -1px rgba(0, 0, 0, 0.1);
`

export const ProductImage = styled.img`
  width: 200px;
  height: 180px;
  object-fit: cover;
  margin-bottom: 12px;
`

export const ProductName = styled.h2`
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
  overflow: hidden;
  height: 32px;
`

export const ProductPrice = styled.div`
  font-size: 20px;
  color: #00ad72;
  font-weight: bold;

  padding: 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
`
export const BuyButton = styled.button`
  background-color: #00ad72;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 5px 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #007b13;
  }
`

export const OldPrice = styled.div`
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
`

export const DiscountBadge = styled.div`
  background-color: #00ad72;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  font-weight: bold;
`

export const BtnNextBack = styled.div`
  background: transparent;
  align-self: center;
  border: none;
  color: #6c757d;
  cursor: pointer;
  font-size: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

  :hover {
    outline: none;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    color: #3f6ad8;
  }
`
