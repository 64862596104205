import { Route, Navigate, Routes, HashRouter } from 'react-router-dom'
import React, { Suspense, lazy } from 'react'
import { ToastContainer } from 'react-toastify'
import { getToken } from '../../Reducers/storageApp'

import Login from '../../Pages/Login'
import Home from '../../Pages/Home'
import SalesOrder from '../../Pages/Sales/Order'

const Financial = lazy(() => import('../../Pages/Financial'))
const Dashboard = lazy(() => import('../../Pages/Dashboard'))
const Formulas = lazy(() => import('../../Pages/Formulas'))
const Planning = lazy(() => import('../../Pages/Planning'))
const Settings = lazy(() => import('../../Pages/Settings'))
const Delivery = lazy(() => import('../../Pages/Delivery'))
const Sales = lazy(() => import('../../Pages/Sales/Query'))

const PrivateRoute = ({ component: Component }) => {
  const token = getToken()
  if (!token) return (window.location.href = '/#/Login')
  return Component
}

export default function App() {
  return (
    <>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-3">
                Aguarde enquanto carregamos todos os componentes
                <small> ... </small>
              </h6>
            </div>
          </div>
        }
      >
        <HashRouter>
          <Routes>
            <Route path="/" exact element={<PrivateRoute component={<Home />} />} />
            <Route path="/Home" element={<PrivateRoute component={<Home />} />} />
            <Route path="/Financial/*" element={<PrivateRoute component={<Financial />} />} />
            <Route path="/Dashboard" element={<PrivateRoute component={<Dashboard />} />} />
            <Route path="/Formulas/*" element={<PrivateRoute component={<Formulas />} />} />
            <Route path="/Planning" element={<PrivateRoute component={<Planning />} />} />
            <Route path="/Settings/*" element={<PrivateRoute component={<Settings />} />} />
            <Route path="/Delivery/*" element={<PrivateRoute component={<Delivery />} />} />
            <Route path="/Sales/*" element={<PrivateRoute component={<Sales />} />} />
          </Routes>

          <Routes>
            <Route path="/c/:id" element={<SalesOrder />} />

            <Route exact path="/" element={<PrivateRoute component={<Navigate to="/Home" />} />} />
            <Route path="/login/*" element={<Login />} />
          </Routes>
        </HashRouter>
      </Suspense>

      <ToastContainer />
    </>
  )
}
