import { LiaPillsSolid } from 'react-icons/lia'
import { BoxComp, BoxIcon, CardComp, RowComp, RowItem, Wrapper } from './styles'
import { BoxInfos, BtnDel, LblDescricao, LblPreco } from '../styles'
import { FaTrashAlt } from 'react-icons/fa'
import { FormatTextFirstUppercase, MaskReal } from '../../../../../../helpers/util'
import { useState } from 'react'

export default function ItemOrdem({ index, value, onDelItem }) {
  const [isOpen, setIsOpen] = useState(false)

  function renderTpFormula() {
    let cond1 = ['Shampoo', 'Creme', 'Gel', 'Floral', 'Xarope', 'Loção', 'Unidades', 'Outras', 'Pomada', 'Homeopatia']
    let cond2 = ['Envelope', 'Cápsula']
    if (cond1.includes(value.tpFormula)) {
      return (
        <LblDescricao>
          {value.tpFormula} | {value.qtde} {value.unMedida}
        </LblDescricao>
      )
    } else if (cond2.includes(value.tpFormula)) {
      return (
        <LblDescricao>
          {value.qtde} {value.tpFormula}s
        </LblDescricao>
      )
    } else return <LblDescricao>{value.tpFormula}</LblDescricao>
  }

  return (
    <Wrapper onClick={() => setIsOpen(!isOpen)}>
      <RowItem>
        <BoxComp>
          <RowComp>
            <LblDescricao>
              {index + 1} - Fórmula manipulada com {value.componentes[0].descricao}
            </LblDescricao>
          </RowComp>
          <RowComp>
            <BoxIcon>
              <LiaPillsSolid size={22} />
            </BoxIcon>
            {renderTpFormula()}
          </RowComp>
        </BoxComp>

        <BoxInfos>
          <LblPreco>{MaskReal(value.valor)}</LblPreco>
          <BtnDel className="btn btn-danger btn-sm" onClick={() => onDelItem(value.pedidoId)}>
            <FaTrashAlt size={16} />
          </BtnDel>
        </BoxInfos>
      </RowItem>

      {isOpen && (
        <CardComp>
          {value.componentes.map((d, cIndex) => (
            <RowComp key={`i${index}_comp${cIndex}`}>
              <LblDescricao>
                {FormatTextFirstUppercase(d.descricao)} {d.concentracaoQt} {d.concentracaoUn}
              </LblDescricao>
            </RowComp>
          ))}

          {value?.fracaoDose ? (
            <RowComp style={{ marginTop: '10px' }}>
              <LblDescricao>2 {value.tpFormula}s = 1 dose</LblDescricao>
            </RowComp>
          ) : (
            <></>
          )}
        </CardComp>
      )}
    </Wrapper>
  )
}
