//Exemplo de uso
//<InputCheck onChange={onSelectItem} name="isValido" value={values.isValido} />
//function onSelectItem(ev) { setValues({...values, [ev.target.name]: ev.target.value})}

//Compativel com a função onChange

import React, { useState, useEffect } from 'react'
import { Container, SelectBox, Label } from './syles'

export default function InputComponent({ value, label, onChange, styleContainer = {}, styleLabel = {}, ...inputProps }) {
  const [state, setstate] = useState(false)

  useEffect(() => {
    if (value !== undefined) setstate(value)
  }, [value])

  const onSelect = (ev) => {
    const { checked } = ev.target
    setstate(checked)

    if (onChange) onChange({ target: { value: checked, ...inputProps } })
  }
  return (
    <Container style={styleContainer} disabled={inputProps.disabled}>
      <label className="checkbox">
        <SelectBox checked={state} type="checkbox" onChange={onSelect} {...inputProps} />
        {inputProps.disabled && state ? <span className="checkmark" style={{ backgroundColor: '#666666' }}></span> : <span className="checkmark"></span>}
      </label>
      {label && <Label style={styleLabel}>{label}</Label>}
    </Container>
  )
}
