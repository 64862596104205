import React, { useState } from 'react'
import ReactSimplyCarousel from 'react-simply-carousel'
import { Wrapper, Main, Title, ContainerCards, BtnNextBack } from './styles'
import Item from './item'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

export default function Suggestion({ records, onAdd }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  return (
    <Wrapper>
      <Title>Sugeridos para você</Title>
      <Main>
        <ReactSimplyCarousel
          activeSlideIndex={activeSlideIndex}
          onRequestChange={setActiveSlideIndex}
          itemsToShow={3}
          itemsToScroll={1}
          speed={400}
          easing="ease-in-out"
          forwardBtnProps={{
            style: { background: 'transparent', alignSelf: 'center', border: 'none', height: 40, width: 40 },
            children: (
              <BtnNextBack>
                <MdChevronRight icon={MdChevronRight} size={26} />
              </BtnNextBack>
            ),
          }}
          backwardBtnProps={{
            style: { background: 'transparent', alignSelf: 'center', border: 'none', height: 40, width: 40 },
            children: (
              <BtnNextBack>
                <MdChevronLeft icon={MdChevronLeft} size={26} />
              </BtnNextBack>
            ),
          }}
          responsiveProps={[
            { minWidth: 768, itemsToShow: 2, itemsToScroll: 1 },
            { maxWidth: 568, itemsToShow: 1, itemsToScroll: 1 },
          ]}
        >
          {records.map((d, index) => (
            <ContainerCards key={`sc_${index}`}>
              <Item key={`s_${index}`} value={d} onAdd={(ev) => onAdd({ value: d, op: 'Add' })} />
            </ContainerCards>
          ))}
        </ReactSimplyCarousel>
      </Main>
    </Wrapper>
  )
}
