import React from 'react'

import PropTypes from 'prop-types'

import { MdCheckCircle } from 'react-icons/md'
import { TimeWrapper } from './styles'
import { ColorlibStepIconRoot } from './styles'
import { moment } from '../../../../../Components'

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <MdCheckCircle />,
    2: <MdCheckCircle />,
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}
export default function TrackTimeline({ steps = [] }) {
  return (
    <TimeWrapper>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div id="tracking-pre"></div>
          <div id="tracking">
            <div className="tracking-list">
              <div className="tracking-item">
                <div className="tracking-icon status-intransit">
                  <svg
                    className="svg-inline--fa fa-circle fa-w-16"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="circle"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                  </svg>
                </div>
                <div className="tracking-date">
                  <img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" className="img-responsive" alt="order-placed" />
                </div>
                <div className="tracking-content">
                  Pedido confirmado<span>{moment().format('DD/MMM HH:mm')}</span>
                </div>
              </div>
              <div className="tracking-item">
                <div className="tracking-icon status-intransit">
                  <svg
                    className="svg-inline--fa fa-circle fa-w-16"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="circle"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                  </svg>
                </div>
                <div className="tracking-date">
                  <img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" className="img-responsive" alt="order-placed" />
                </div>
                <div className="tracking-content">
                  Pagamento aprovado<span>{moment().format('DD/MMM HH:mm')}</span>
                </div>
              </div>
              <div className="tracking-item">
                <div className="tracking-icon status-intransit">
                  <svg
                    className="svg-inline--fa fa-circle fa-w-16"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="circle"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                  </svg>
                </div>
                <div className="tracking-date">
                  <img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" className="img-responsive" alt="order-placed" />
                </div>
                <div className="tracking-content">
                  Produção finalizada<span>{moment().format('DD/MMM HH:mm')}</span>
                </div>
              </div>
              <div className="tracking-item">
                <div className="tracking-icon status-intransit">
                  <svg
                    className="svg-inline--fa fa-circle fa-w-16"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="circle"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                  </svg>
                </div>
                <div className="tracking-date">
                  <img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" className="img-responsive" alt="order-placed" />
                </div>
                <div className="tracking-content">
                  Saiu para entrega<span>{moment().format('DD/MMM HH:mm')}</span>
                </div>
              </div>

              <div className="tracking-item-pending">
                <div className="tracking-icon status-intransit">
                  <svg
                    className="svg-inline--fa fa-circle fa-w-16"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="circle"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                  </svg>
                </div>
                <div className="tracking-date">
                  <img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" className="img-responsive" alt="order-placed" />
                </div>
                <div className="tracking-content">Entrega em andamento</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TimeWrapper>
  )
}
