import styled from 'styled-components'
import bgImagem from '../../../../assets/images/logo2.png'

export const Wrapper = styled.div`
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
  background-image: linear-gradient(to right, #684fa9 0%, #684fa9 19%, #7a63b6 60%, #9886c6 100%) !important;

  height: 70px;
  transition: all 0.2s;
  @media (max-width: 768px) {
    margin: 0;
  }
`

export const BoxLogo = styled.div`
  padding: 0 0.5rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.2s;
`

export const ImgLogo = styled.div`
  background-image: url(${bgImagem});
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto auto;
  height: 36px;
  width: 100%;
  @media (max-width: 768px) {
    background-position: center center;
  }
`
