import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh -100px);
`
export const TextMsg = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #684fa9;
  margin: -20px 0 40px;
  text-align: center;
`

export const BtnRastreamento = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 70%;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #27ae60;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
  span {
    float: right;
  }
`
