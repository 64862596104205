import React from 'react'

import { Stack, Stepper, Step, StepLabel, styled } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import PropTypes from 'prop-types'
import { FaRegCheckCircle } from 'react-icons/fa'
import { FaListCheck } from 'react-icons/fa6'
import { GrMapLocation } from 'react-icons/gr'
import { HiOutlineCreditCard } from 'react-icons/hi'
import { Wrapper } from './styles'
import { BoxContent } from '../../styles'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg, #dd9d08 0%, #f7b924 50%, #f9c753 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 136deg, #2f9d64 0%, #3ac47d 50%, #62d097 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: 'white',
  width: 38,
  height: 38,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, #dd9d08 0%, #f7b924 50%, #f9c753 100%)',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, #2f9d64 0%, #3ac47d 50%, #62d097 100%)',
  }),
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <FaListCheck size={20} />,
    2: <GrMapLocation size={20} />,
    3: <HiOutlineCreditCard size={24} />,
    4: <FaRegCheckCircle size={20} />,
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

export default function Timeline({ stepId, onStepNext }) {
  const steps = [
    { index: 0, label: 'Itens' },
    { index: 1, label: 'Entrega' },
    { index: 2, label: 'Pagamento' },
    { index: 3, label: 'Conclusão' },
  ]

  function onNext(ev) {
    if (ev < 3) {
      onStepNext(ev)
    }
  }
  return (
    <Wrapper>
      <BoxContent>
        <Stack sx={{ width: '100%' }} spacing={10}>
          {steps.length > 0 && (
            <Stepper alternativeLabel activeStep={stepId === 3 ? 4 : stepId} connector={<ColorlibConnector />}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel onClick={() => onNext(index)} StepIconComponent={ColorlibStepIcon}>
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
        </Stack>
      </BoxContent>
    </Wrapper>
  )
}
