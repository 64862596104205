import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  height: 75px;
  background: var(--bg-color);
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

  margin-top: -6px;
  position: sticky;
  top: 0;
  z-index: 1;

  .MuiStepLabel-label {
    font-size: 14px;
    margin-top: 4px !important;
  }
`
