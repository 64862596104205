function jsonpRequest(url, callbackName) {
  console.log('Loading JSONP script from URL:', url)

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = `${url}&callback=${callbackName}`
    document.body.appendChild(script)

    window[callbackName] = (data) => {
      console.log('JSONP response received:', data)
      resolve(data)
      document.body.removeChild(script)
    }

    script.onerror = (error) => {
      console.error('Script loading failed:', error)
      reject(new Error('JSONP request failed'))
      document.body.removeChild(script)
    }
  })
}

export const getProductsWordPress = async (ids) => {
  try {
    const response = await fetch(
      'https://api.performa.ai/v1/?type=recommendation&pubkey=71F427DD-D4CB-B761-8645-9A54CCA4D038&params=%7B%22route%22:%22products/bestsellers%22,%22labels%22:%22powerslider%22,%22limit%22:100,%22random%22:false,%22order_field%22:%22-1%22,%22order_type%22:%22%22%7D&ts=1724261072',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) {
      throw new Error('Erro ao buscar produtos da API')
    }

    const data = await response.json()

    const products = data.product_data
      .filter((product) => ids.includes(product.id))
      .map((product) => ({
        id: product.id,
        name: product.name,
        description: product.type ? product.type.join(', ') : '',
        price: product.price,
        oldPrice: product.list_price,
        image: product.images.image,
      }))

    return products
  } catch (error) {
    console.error('Erro na função getProductsWordPress:', error)
    return []
  }
}
