import { CardHeader, Col } from 'reactstrap'
import styled from 'styled-components'

export const Main = styled.div`
  min-height: calc(100vh - 175px);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
`
export const ItemHeader = styled(CardHeader)`
  display: flex !important;
  justify-content: space-between;
  padding-inline: 20px !important;
`
export const WrapperTotal = styled(Col)`
  /* background-color: green;
  float: right;
  position: absolute;
  bottom: 0; */
`

export const BtnNext = styled.button`
  width: 100%;
  background-color: #00ad72;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 8px;
  margin-top: 12px;
  cursor: pointer;
  transition: background-color 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #007b13;
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.showlarge ? 'none' : 'unset')};
  }
`
