import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function MessageModal({ change, type, title, content, children }) {
  return (
    <Modal isOpen={true} toggle={() => change()} style={{ width: '100%' }}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children ? children : <h5 className="text-danger">{content}</h5>}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => change()}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  )
}
