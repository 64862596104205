import React from 'react'
import { DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, UncontrolledButtonDropdown } from 'reactstrap'
import { FaAngleDown } from 'react-icons/fa'

import avatar1 from '../../assets/images/user.png'
import { getUser } from '../../Reducers/storageApp'

function UserBox() {
  const { name: userName, imgSource } = getUser()

  function onSair() {
    localStorage.clear()
    window.location.href = '/#/login'
  }

  return (
    <>
      <div className="header-dots pr-0">
        <div className="widget-content">
          <div className="widget-content-wrapper">
            <div className="widget-content-left  ml-3 header-user-info">
              <div className="widget-heading text-center"></div>
            </div>

            {/* <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="rounded-circle-Dropdown">
                  <div className="btn-icon btn-icon-only btn btn-link btn-sm">
                    <MdMessage className="ml-2 opacity-9" color="var(--white)" size={28} />
                    <span className="badge badge-pill badge-danger">2</span>
                  </div>
                </DropdownToggle>
                <DropdownMenu className="rm-pointers dropdown-menu-lg">
                  <Nav vertical>
                    <NavItem className="nav-item-header">XX</NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div> */}
          </div>
        </div>
      </div>

      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left ml-2">
              <UncontrolledButtonDropdown>
                <DropdownToggle style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} color="link" className="p-0">
                  <div className="widget-content-left  header-user-info">
                    <div style={{ marginRight: '5px' }} className="widget-heading">
                      {userName}
                    </div>
                  </div>
                  <img width={34} className="rounded-circle" src={imgSource ? imgSource : avatar1} alt="" />
                  <FaAngleDown className="ml-2 opacity-8" color="var(--color-menu)" size={18} />
                </DropdownToggle>

                <DropdownMenu className="rm-pointers dropdown-menu-lg">
                  <Nav vertical>
                    <NavItem>
                      <NavLink href="/#/Settings/User/Profile">Perfil</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink onClick={onSair}>Sair</NavLink>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserBox
