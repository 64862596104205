import { Card, CardBody, Col, Label, Row, FormGroup, Input } from 'reactstrap'
import { BtnNext, ItemHeader } from '../styles'
import { useEffect, useState } from 'react'
import { FaTruck } from 'react-icons/fa'
import { FaHouseFlag } from 'react-icons/fa6'
import { FilialGetAll, ParticipanteGetCep } from '../../../../../Service/ApiService'
import { Bounce, InputCep, Select, toast } from '../../../../../Components'
import { OptItem, OptMain } from './styles'
import { HiOutlineCreditCard } from 'react-icons/hi'

export default function CardEntrega({ value, change, stepId }) {
  const [filiais, setFiliais] = useState([])
  const [values, setValues] = useState()

  useEffect(() => {
    if (value && !values) loadValues()
  }, [value])

  useEffect(() => {
    async function loadCep() {
      var result = await ParticipanteGetCep(values.endCep)

      if (result.msgResultado === 'ok') {
        setValues({
          ...values,
          endLogradouro: result.logradouro,
          endBairro: result.bairro,
          endCidade: result.localidade,
          endEstado: result.uf,
        })
      } else {
        toast('CEP não encontrado', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      }
    }

    if (values?.endCep === undefined) return
    if (values.endCep?.length === 9) loadCep()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.endCep])

  async function loadValues() {
    var filRetirada = await loadLojas(value.tpEntrega, value.filialIdRet)
    setValues({
      endCep: value.endCep,
      endLogradouro: value.endLogradouro,
      endNumero: value.endNumero,
      endComp: value.endComp,
      endBairro: value.endBairro,
      endCidade: value.endCidade,
      filRetirada,
    })

    change({ value: { ...value, ...values }, stepId })
  }

  async function loadLojas(tpEntrega, filialIdRet) {
    const response = await FilialGetAll()
    let _filiais = []
    let _return = {}
    if (response) {
      response.forEach((d) => {
        if (d.nome.length > 0 && d.tpLogistica === 'R') {
          _filiais.push({ value: d.filialId, label: `${d.codigo} - ${d.nome}`, endereco: d.endereco })

          if (tpEntrega === 'R' && filialIdRet == d.filialId) _return = { value: d.filialId, label: `${d.codigo} - ${d.nome}`, endereco: d.endereco }
        }
      })
    }
    setFiliais(_filiais)

    return _return
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  return (
    <Card className="main-card mb-2" style={{ width: '100%' }}>
      <ItemHeader>Entrega</ItemHeader>
      <CardBody>
        {values && (
          <Row>
            <Col xs={12}>
              <OptMain>
                <OptItem selected={value.tpEntrega == 'R'} name="tpEntrega" value="R" onClick={() => change({ value: { ...value, tpEntrega: 'R' }, stepId })}>
                  <FaHouseFlag size={26} />
                  <span>Retirar na loja</span>
                </OptItem>

                <OptItem selected={value.tpEntrega == 'E'} name="tpEntrega" value="E" onClick={() => change({ value: { ...value, tpEntrega: 'E' }, stepId })}>
                  <FaTruck size={26} />
                  <span>Entregar</span>
                </OptItem>
              </OptMain>

              {value.tpEntrega === 'R' && (
                <Row>
                  <Col md={5}>
                    <FormGroup>
                      <Label>Selecione a unidade para a retirar *</Label>
                      <Select
                        placeholder="Selecione"
                        onChange={onChangeCat}
                        noOptionsMessage={() => 'Nenhuma opção disponível'}
                        value={values.filRetirada}
                        isClearable
                        name="filRetirada"
                        className="basic-single-select"
                        classNamePrefix="select"
                        options={filiais}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={7}>
                    <FormGroup>
                      <Label>Endereço da loja</Label>
                      <Input type="text" name="filialEnd" id="filialEnd" value={values.filRetirada?.endereco} disabled />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {value.tpEntrega === 'E' && (
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Cep *</Label>
                      <InputCep onChange={onChange} value={values.endCep} name="endCep" id="endCep" />
                    </FormGroup>
                  </Col>
                  <Col md={7}>
                    <FormGroup>
                      <Label>Rua </Label>
                      <Input type="text" disabled value={values.endLogradouro} name="endLogradouro" id="endLogradouro" />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Numero </Label>
                      <Input type="text" onChange={onChange} value={values.endNumero} name="endNumero" id="endNumero" />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Complemento </Label>
                      <Input type="text" onChange={onChange} value={values.endComp} name="endComp" id="endComp" />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup>
                      <Label>Bairro </Label>
                      <Input type="text" disabled value={values.endBairro} name="endBairro" id="endBairro" />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Cidade/Estado </Label>
                      <Input type="text" disabled value={values.endCidade} name="endCidade" id="endCidade" />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Col>

            <BtnNext showlarge={true} onClick={() => change({ value: { ...value, ...values }, stepId: 2 })}>
              <HiOutlineCreditCard size={22} className="mr-2" /> <span>Ir para pagamento</span>
            </BtnNext>
          </Row>
        )}
      </CardBody>
    </Card>
  )
}
