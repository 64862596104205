import React from 'react'
import { AppHeader, AppSidebar, AppFooter } from '../../Layout'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Lottie from 'react-lottie'
import animationData from './welcome2.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
}

export default function Dashboards() {
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="page-content" style={{ height: 'calc(100vh - 130px)', display: 'flex' }}>
              <div style={{ margin: 'auto 0', width: '100%' }}>
                <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                  <Card className="main-card mb-2">
                    <CardBody style={{ textAlign: 'center' }}>
                      <Row>
                        <Col md={12}>
                          <h2> Seja bem-vindo(a) a PCP Web M2M </h2>
                        </Col>
                        <Col md={12}>
                          <Lottie options={defaultOptions} style={{ maxHeight: '600px', maxWidth: '700px' }} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </ReactCSSTransitionGroup>
              </div>
            </div>
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}
