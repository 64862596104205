import styled from 'styled-components'

export const InputSales = styled.input`
  display: block;
  font-size: 1rem;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  background: transparent;
  border: none;
  border-bottom: 1px solid grey;
  &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
  }
`
