import React from 'react'
import Lottie from 'react-lottie'
import animationData from './animationData.json'
import { Wrapper, Container } from './styles'

export default function Element({ isVisible }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  }
  return (
    <>
      {isVisible && (
        <Wrapper>
          <Container>
            <Lottie options={defaultOptions} style={{ maxHeight: '200px', maxWidth: '200px' }} />
          </Container>
        </Wrapper>
      )}
    </>
  )
}
