import { Card, CardBody } from 'reactstrap'
import { normaliseValue } from '../../../../../helpers/util'
import { BoxDtEntrega, ItemTotal, TextTotal, TotalContainer } from './styles'
import { BsInfoCircle } from 'react-icons/bs'
import moment from 'moment'
import { BtnNext } from '../styles'

export default function CardTotal({ value, change, stepId }) {
  const calculateTotal = () => {
    let total = 0.0

    value.itens.forEach((d) => {
      if (!value.pedidos.filter((f) => f.pedidoId == d.pedidoId)[0].isExcluido) total += d.valor * d.qtdPote
    })

    value.itensAdicionais?.forEach((d) => {
      total += d.price * d.qtde
    })
    return normaliseValue(total)
  }

  return (
    <Card className="main-card mb-1 pl-2 pr-2" style={{ width: '100%' }}>
      <CardBody>
        <TotalContainer>
          <ItemTotal style={{ fontWeight: '600' }}>
            <span>Subtotal:</span> <span>R$ {calculateTotal()}</span>
          </ItemTotal>
          <ItemTotal>
            Desconto: <span>-</span>
          </ItemTotal>
          <ItemTotal>
            Entrega: <span style={{ color: 'rgb(14, 178, 83)', fontWeight: '600' }}>Grátis</span>
          </ItemTotal>
          <BoxDtEntrega>
            <span>Receba seu pedido até o dia {moment(value.dhCadastro).add('days', 2).format('DD/MMM')}</span>

            <span>
              <BsInfoCircle className="mr-2" />
              Válido para pedidos concluidos hoje até as 17:00
            </span>
          </BoxDtEntrega>
          <TextTotal>
            <p>
              <span>Total: </span>
            </p>
            <p>
              <span> R$ </span>
              {calculateTotal()}
            </p>
          </TextTotal>
        </TotalContainer>

        <BtnNext showlarge={false} onClick={() => change({ value, stepId: stepId + 1 })}>
          {
            {
              0: <span>Ir para dados de entrega</span>,
              1: <span>Ir para pagamento</span>,
              2: <span>Finalizar minha compra</span>,
            }[stepId]
          }
        </BtnNext>
      </CardBody>
    </Card>
  )
}
