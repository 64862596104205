export function MaskReal(value) {
  var valueFloat = parseFloat(value).toFixed(2)

  var tmp = valueFloat
    .toString()
    .replace(/\D/g, '')
    .replace(/([0-9]{2})$/g, ',$1')
  if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')

  return 'R$ ' + tmp
}

export function normaliseValue(value, decimals = 2) {
  if (!value) return '-'

  if (value === '.') return '0.'

  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)
  const decimalsNumber = value.toString().match(regex)[0]

  let parsed = parseFloat(decimalsNumber).toFixed(decimals)

  parsed = parsed.replace('.', ',')

  const parts = parsed.split(',')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  return parts.join(',')
}

export function formatarDataParaAnoMesDia(value) {
  if (value === undefined || value === null || ('' + value).indexOf('/') <= 0) return value
  let dataSplit = value.split('/')
  return dataSplit[2] + '-' + dataSplit[1] + '-' + dataSplit[0]
}

export function formatarDataParaDiaMesAno(value) {
  if (value === undefined || value === null || ('' + value).indexOf('-') <= 0) return value
  let dataSplit = value.split('-')
  return dataSplit[2] + '/' + dataSplit[1] + '/' + dataSplit[0]
}

export function CpfIsValid(value) {
  var Soma
  var Resto
  Soma = 0
  if (value == '00000000000') return false
  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(value.substring(i - 1, i)) * (11 - i)
  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0
  if (Resto != parseInt(value.substring(9, 10))) return false

  Soma = 0
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(value.substring(i - 1, i)) * (12 - i)
  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0
  if (Resto != parseInt(value.substring(10, 11))) return false
  return true
}
export function abbreviateNumber(number) {
  var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']

  var tier = (Math.log10(Math.abs(number)) / 3) | 0
  if (tier == 0) return number.toFixed(1)
  var suffix = SI_SYMBOL[tier]
  var scale = Math.pow(10, tier * 3)
  var scaled = number / scale
  return scaled.toFixed(1) + suffix
}

export function FormatTextFirstUppercase(value) {
  if (!value) return ''

  if (value == '') return ''
  return value
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
