import { Card, CardBody, Col, Label, Row, FormGroup, Input } from 'reactstrap'
import { BtnNext, ItemHeader } from '../styles'
import { OptMain, PayItem } from './styles'
import { FaMoneyBillWave, FaRegCheckCircle, FaTruck } from 'react-icons/fa'
import { BsCreditCard } from 'react-icons/bs'
import { Select } from '../../../../../Components'

export default function CardPagamento({ value, change, stepId }) {
  const calculateTotal = () => {
    let total = 0
    value.itens.forEach((item) => {
      total += item.valor * item.qtdPote
    })
    return total
  }
  const parcelas = Array.from({ length: 10 }, (_, i) => {
    const numParcelas = i + 1
    const valorParcela = calcularParcelas(calculateTotal(), numParcelas)
    return {
      value: numParcelas,
      label: `${numParcelas}x de R$ ${valorParcela} sem juros`,
    }
  })
  function calcularParcelas(numParcelas) {
    return (calculateTotal() / numParcelas).toFixed(2)
  }

  return (
    <Card className="main-card mb-3" style={{ width: '100%' }}>
      <ItemHeader>Pagamento</ItemHeader>
      <CardBody>
        <OptMain>
          <PayItem selected={value.tpPagamento == 'C'} name="tpPagamento" value="C" onClick={() => change({ value: { ...value, tpPagamento: 'C' }, stepId })}>
            <BsCreditCard size={32} />
            <span>Cartão de Crédito</span>
          </PayItem>

          <PayItem selected={value.tpPagamento == 'P'} name="tpPagamento" value="P" onClick={() => change({ value: { ...value, tpPagamento: 'P' }, stepId })}>
            <FaMoneyBillWave size={32} />
            <span>Pix</span>
          </PayItem>

          <PayItem selected={value.tpPagamento == 'E'} name="tpPagamento" value="E" onClick={() => change({ value: { ...value, tpPagamento: 'E' }, stepId })}>
            <FaTruck size={32} />
            <span>Pagar na entrega</span>
          </PayItem>
        </OptMain>

        {value.tpPagamento === 'C' && (
          <Row>
            <Col md={7}>
              <FormGroup>
                <Label>Número do Cartão: *</Label>
                <Input type="text" name="cartaoNumero" maxLength={19} placeholder="Número do cartão" />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Validade: *</Label>
                <Input type="text" name="cartaoExpiracao" value={value.cartaoExpiracao} maxLength={5} placeholder="MM/AA" />
              </FormGroup>
            </Col>

            <Col md={2}>
              <FormGroup>
                <Label>CVV: *</Label>
                <Input type="text" name="cartaoCVV" value={value.cartaoCVV} maxLength={3} placeholder="CVV" />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <Label>Parcelamento: *</Label>
                <Select placeholder="Selecione o número de parcelas" name="parcelamento" noOptionsMessage={() => 'Nenhuma opção disponível'} value={value.parcelamento} options={parcelas} />
              </FormGroup>
            </Col>
          </Row>
        )}

        <BtnNext showlarge={true} onClick={() => change({ value: { ...value, situacao: 10 }, stepId: 3 })}>
          <FaRegCheckCircle size={22} className="mr-2" /> <span>Finalizar minha compra</span>
        </BtnNext>
      </CardBody>
    </Card>
  )
}
