import api from './api'
const URL_BASE = process.env.REACT_APP_API

export const GetTokenApp = async () => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/TokenApp`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: `"${process.env.REACT_APP_KEYAPI}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}

export const LoginUsuario = async (token, value) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/Login`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
      body: `"${value}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const RecuperarSenha = async (email) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/RecuperarLogin`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(email),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const IsHashValido = async (hash) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/IsHashValido`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(hash),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const AltSenha = async (values) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/AltSenha`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(values),
    })
    const json = req.json()
    return json
  } catch (error) {
    return undefined
  }
}

//Usuario
export const UsuarioGet = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Usuario/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetById = async (Id) => {
  try {
    const req = await api.get(`/Usuario/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetConvite = async (id) => {
  try {
    const req = await api.get(`/Usuario/GetConvite?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetAcessos = async () => {
  try {
    const req = await api.get(`/Usuario/GetAcessos`)
    return req.data
  } catch (error) {
    return undefined
  }
}
//Perfil
export const PerfilAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Perfil/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PerfilGet = async (filters) => {
  try {
    const req = await api.post('/Perfil/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PerfilGetById = async (Id) => {
  try {
    const req = await api.get(`/Perfil/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PerfilGetPerFuncao = async (perfilId) => {
  try {
    const req = await api.get(`/Perfil/GetPerFuncao?perfilId=${perfilId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//SysParametros
export const SysParametroGet = async () => {
  try {
    const req = await api.get(`/SysParametro/GetAll`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysParametroAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/SysParametro/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const SysTpReagendamentoGet = async () => {
  try {
    const req = await api.get(`/SysParametro/TpReagendamentoGetAll`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysTpReagendamentoAddOrUp = async (values) => {
  try {
    const req = await api.post('/SysParametro/TpReagendamentoAddOrUp', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Filial
export const FilialAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Filial/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FilialGetAll = async () => {
  try {
    const req = await api.get(`/Filial/GetAll`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FilialGetById = async (id) => {
  try {
    const req = await api.get(`/Filial/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Romaneio
export const RomaneioAdd = async (values) => {
  try {
    const req = await api.post('/Romaneio/Add', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const GetRomaneiosPanel = async (filter) => {
  try {
    const req = await api.post(`/Romaneio/GetRomaneiosPanel`, filter)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const GetRomFCertaByBarCode = async (value) => {
  try {
    const req = await api.post(`/Romaneio/GetRomFCertaByBarCode`, `"${value}"`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const RomaneioGetRelDivergencias = async (values) => {
  try {
    const req = await api.post('/Romaneio/GetRelDivergencias', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const RomaneioGetRelPendentes = async (filter) => {
  try {
    const req = await api.post('/Romaneio/GetRelPendentes', filter)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const RomaneioSendVuupt = async (id) => {
  try {
    const req = await api.get(`/Romaneio/SendVuupt?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Pedidos
export const PedidoGetForAcompanhamento = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetForAcompanhamento', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetDashPlanning = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetDashPlanning', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const PedidoGetDadosKPIs = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetDadosKPIS', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetReagendamentoDetalhes = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetReagendamentoDetalhes', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetDtEntregaDetalhes = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetDtEntregaDetalhes', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetKPIsManager = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetKPIsManager', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetKPIsManager_Orcamentos = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetKPIsManager_Orcamentos', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const GetVendasFiliais = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetVendasFiliais', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetVendasFuncionario = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetVendasFuncionario', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoSetEtapa = async (filters) => {
  try {
    const req = await api.post('/Pedido/SetEtapa', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoPedEtapaLogGetByUsuario = async (usuarioId) => {
  try {
    const req = await api.get(`/Pedido/PedEtapaLogGetByUsuario?usuarioId=${usuarioId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetCdEtapas = async () => {
  try {
    const req = await api.post(`/Pedido/GetCdEtapas`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetTpFormulas = async () => {
  try {
    const req = await api.post(`/Pedido/GetTpFormulas`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetFormulasByBarCode = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetFormulasByBarCode', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FichasGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetByFilterFichas', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ImpressaoFichasGetById = async (PedidoId) => {
  try {
    const req = await api.post('/Pedido/GetByIdImpressaoFichas', { PedidoId })
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoGetByBarcodeEtiquetaRastreamento = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetByBarcodeEtiquetaRastreamento', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
//Pedido Conferência
export const PedidoGetForConferencia = async (filters) => {
  try {
    const req = await api.post('/Pedido/GetForConferencia', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PedidoUpConferencia = async (op, values) => {
  try {
    const req = await api.post(`/Pedido/UpConferencia?op=${op}`, values)
    return req.data
  } catch (error) {
    return undefined
  }
}
//Pedido Reagendamento
export const PedidoReagendarEnt = async (values) => {
  try {
    const req = await api.post('/Pedido/ReagendarEnt', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//FinMeta
export const FinMetaGetByFilter = async (filter) => {
  try {
    const req = await api.post(`/FinMeta/GetByFilter`, filter)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FinMetaAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/FinMeta/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FinMetaGetById = async (id) => {
  try {
    const req = await api.get(`/FinMeta/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FinMetaDelete = async (id) => {
  try {
    const req = await api.delete(`/FinMeta?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Participantes
export const ParticipanteGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Participante/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteGetById = async (Id) => {
  try {
    const req = await api.get(`/Participante/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Participante/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteVerifyEntLivre = async (filters) => {
  try {
    const req = await api.post('/Participante/VerifyEntLivre', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteGetByEntregador = async (ParticipanteId) => {
  try {
    const req = await api.get(`/Participante/GetByEntregador?participanteId=${ParticipanteId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteGetCep = async (value) => {
  try {
    const req = await api.get(`/Participante/GetCep?EndCEP=${value}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
//Laboratorios
export const LaboratorioGetAll = async () => {
  try {
    const req = await api.post(`/Laboratorio/GetAll`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LaboratorioAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Laboratorio/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LaboratorioGetById = async (id) => {
  try {
    const req = await api.get(`/Laboratorio/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteGetEntByFilter = async (filters) => {
  try {
    const req = await api.post('/Participante/GetEntByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const LaboratorioGetEtapasPCP = async () => {
  try {
    const req = await api.get(`/Laboratorio/GetEtapasPCP`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Feriados
export const FeriadoGetByFilter = async (filter) => {
  try {
    const req = await api.post(`/Feriado/GetByFilter`, filter)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FeriadoAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Feriado/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FeriadoGetDel = async (id) => {
  try {
    const req = await api.delete(`/Feriado/Delete?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const FeriadoGetById = async (id) => {
  try {
    const req = await api.get(`/Feriado/GetById?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Orçamento
export const OrcamentoGetRequisicao = async (filialId, nrRequisicao) => {
  try {
    const req = await api.get(`/Orcamento/GetRequisicao?filialId=${filialId}&nrRequisicao=${nrRequisicao}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const OrcamentoAdd = async (values) => {
  try {
    const req = await api.post('/Orcamento/Add', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const OrcamentoUp = async (values) => {
  try {
    const req = await api.post('/Orcamento/Update', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const OrcamentoGetByFilter = async (filter) => {
  try {
    const req = await api.post(`/Orcamento/GetByFilter`, filter)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const OrcamentoGetForDash = async (filter) => {
  try {
    const req = await api.post(`/Orcamento/GetForDash`, filter)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const OrcamentoGetByKey = async (key) => {
  try {
    const req = await fetch(`${URL_BASE}/Orcamento/GetByKey?key=${key}`, {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    })
    const json = await req.json()
    return json
  } catch (error) {
    console.log(error)
    return undefined
  }
}
