import { createStore } from 'redux'
//import { loadState, saveState } from './localStorage'

function storeAppL(
  state = {
    enableMobileMenu: false,
    enableClosedSidebar: true,
    enableMobileMenuSmall: false,
    formulaQuery: true, //showTotais
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'enableMobileMenu':
      return { ...state, enableMobileMenu: action.value }
    case 'enableClosedSidebar':
      return { ...state, enableClosedSidebar: action.value }
    case 'enableMobileMenuSmall':
      return { ...state, enableMobileMenuSmall: action.value }

    case 'formulaQuery':
      return { ...state, formulaQuery: action.value }
  }
  return state
}

const store = createStore(storeAppL)
export default store
