import { useEffect, useState } from 'react'
import { BoxContent } from '../styles'
import Timeline from './Steps'
import { Main, WrapperTotal } from './styles'
import { Col, Row } from 'reactstrap'
import CardResumo from './CardResumo'
import CardTotal from './CardTotal'
import CardEntrega from './CardEntrega'
import CardPagamento from './CardPagamento'
import CardMsgFinal from './CardMsgFinal'
import { getProductsWordPress } from '../../../../Service/ApiWordPress'

import { Bounce, toast, useParams } from '../../../../Components'
import { OrcamentoUp } from '../../../../Service/ApiService'

export default function Checkout({ value }) {
  const { id } = useParams()
  const [stepId, setStepId] = useState(0)
  const [sugeridos, setSugeridos] = useState([])
  const [values, setValues] = useState()

  async function loadSugeridos() {
    const response = await getProductsWordPress(['7434543032', '7360899092', '7210733082', '2581146505'])
    if (response) setSugeridos([...response, ...response])
  }

  async function loadOrcamento() {
    loadSugeridos()

    setValues(value)
    //   let response = await OrcamentoGetByKey(id)

    //   if (response) setValues({ ...response, tpPagamento: 'C' })
    //   else toast('Ocorreu um problema ao realizar a consulta', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
  }
  useEffect(() => {
    loadOrcamento()
  }, [value])

  async function ChangeItem(ev) {
    console.log(ev)
    setValues(ev.value)

    if (!validStepNext(ev.stepId, ev.value)) return

    if (ev.stepId == 3) {
      if ((await onSubmit({ ...ev.value, situacao: 10 })) == false) return
    } else {
      await onSubmit(ev.value)
    }

    setStepId(ev.stepId)
  }

  function onUpSugestao(ev) {
    let _itensAdicionais = []
    if (ev.op === 'Add') {
      let _item = values.itensAdicionais?.filter((f) => f.id == ev.value.id)
      if (_item?.length > 0) {
        values.itensAdicionais.forEach((d) => {
          if (d.id == ev.value.id) _itensAdicionais.push({ ...d, qtde: d.qtde + 1 })
          else _itensAdicionais.push(d)
        })
      } else {
        _itensAdicionais = values.itensAdicionais ? values.itensAdicionais : []
        _itensAdicionais.push({ ...ev.value, qtde: 1 })
      }
    } else if (ev.op === 'Del') {
      _itensAdicionais = values.itensAdicionais.filter((f) => f.id != ev.value.id)
    } else if (ev.op === 'Dec') {
      values.itensAdicionais.forEach((d) => {
        if (d.id == ev.value.id) {
          if (d.qtde - 1 > 0) _itensAdicionais.push({ ...d, qtde: d.qtde - 1 })
        } else _itensAdicionais.push(d)
      })
    }
    setValues({ ...values, itensAdicionais: _itensAdicionais })
  }
  function onDelItem(pedidoId) {
    let _pedidos = []

    values.pedidos.forEach((d) => {
      if (d.pedidoId == pedidoId) _pedidos.push({ ...d, isExcluido: true })
      else _pedidos.push(d)
    })

    setValues({ ...values, pedidos: _pedidos })
  }

  async function onSubmit(value) {
    try {
      let _values = { ...value }
      if (value.tpEntrega == 'R' && value.filRetirada?.value) _values.filialIdRet = value.filRetirada?.value

      const response = await OrcamentoUp(_values)
      if (response?.result) return true
      else {
        if (!response || response === undefined)
          toast('Ocorreu um problema ao salvar o registro! Tente novamente!', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        else toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
    } catch (errr) {
      console.log('onSubmit', errr)
    }
  }

  function validStepNext(stepUp, value) {
    if (stepUp < stepId || stepUp == stepId) return true

    //valida os itens do pedido
    if (stepId === 0) {
      if (value.itens?.length === 0 && value.itensAdicionais?.length === 0) {
        toast('Você precia adicionar um item ao carrinho para avançar', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
    } else if (stepId === 1) {
      if (value.tpEntrega == 'R' && !value.filRetirada?.value) {
        toast('Para retirar, selecione a loja desejada', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
      if (value.tpEntrega == 'E' && (!value.endCep || !value.endNumero)) {
        toast('Informe o endereço para entrega', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
    } else if (stepId === 2) {
      // if (value.tpPagamento == 'C' && (!value.endCep || !value.endNumero)) {
      //   toast('Informe o endereço para entrega', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      //   return false
      // }
    }

    return true
  }

  function onStepNext(ev) {
    if (validStepNext(ev, values)) setStepId(ev)
  }

  return (
    <>
      <Timeline stepId={stepId} onStepNext={onStepNext} />

      <Main>
        {values && (
          <BoxContent>
            <Row style={{ width: '100%' }}>
              {stepId === 3 ? (
                <CardMsgFinal stepId={stepId} value={values} change={ChangeItem} />
              ) : (
                <>
                  <Col md={8}>
                    {
                      {
                        0: <CardResumo sugeridos={sugeridos} stepId={stepId} value={values} change={ChangeItem} onUpSugestao={onUpSugestao} onDelItem={onDelItem} />,
                        1: <CardEntrega stepId={stepId} value={values} change={ChangeItem} />,
                        2: <CardPagamento stepId={stepId} value={values} change={ChangeItem} />,
                      }[stepId]
                    }
                  </Col>
                  <WrapperTotal md={4}>
                    <CardTotal stepId={stepId} value={values} change={ChangeItem} />
                  </WrapperTotal>
                </>
              )}
            </Row>
          </BoxContent>
        )}
      </Main>
    </>
  )
}
