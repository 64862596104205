import React from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import AppLogo from '../AppLogo'
import UserBox from './UserBox'

export default function Header() {
  const enableMobileMenuSmall = useSelector((state) => state.enableMobileMenuSmall)
  let { headerBackgroundColor = ' header-text-light bg-strong-bliss', enableHeaderShadow = true } = {}

  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        className={cx('app-header', headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AppLogo />

        <div className={cx('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })}>
          <div className="app-header-left">{/* <SearchBox /> */}</div>

          <div className="app-header-right">
            <UserBox />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  )
}
