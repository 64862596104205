import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: -2px;
`

export const BoxOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 5px;
  div:nth-child(1) {
    margin-right: 20px;
  }
`

export const Main = styled.form``
