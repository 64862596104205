import { CardTitle, Table } from 'reactstrap'
import { Wrapper, Header, CardMain, MainTimeLine, MainContent, Main } from './styles'
import TrackTimeline from './Timeline'
import { MaskReal } from '../../../../helpers/util'
import moment from 'moment'

export default function Tracking({ value }) {
  return (
    <Wrapper>
      <Main>
        <Header>
          <div>
            <span>Romaneio</span>
            <span>10203040</span>
          </div>
          <div>
            <span>Pedido feito em:</span>
            <span style={{ marginLeft: 'auto' }}>{moment(value.orcamento.dhCadastro).format('DD/MM/YYYY HH:mm')}</span>
          </div>
        </Header>
        <CardMain>
          <MainTimeLine>
            <TrackTimeline
              steps={[
                { label: 'João da Silva', date: '27/08/24 10:24' },
                { label: 'Luciano Santos', date: '' },
              ]}
            />
          </MainTimeLine>
          <MainContent>
            <CardTitle>
              <span>Itens</span>
            </CardTitle>
            <div className="tb-report position-relative table-responsive">
              <Table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th>Descrição</th>
                    <th style={{ textAlign: 'center' }}>Tipo</th>
                    <th style={{ textAlign: 'center' }}>Qtde</th>
                    <th style={{ textAlign: 'right' }}>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {value?.itens?.map((p, index) => (
                    <tr key={`Rem-${p.pedidoId}`}>
                      <td style={{ fontWeight: 600 }}>{index + 1}</td>
                      <td>Fórmula homeopatica com {p.componentes[0].descricao}</td>
                      <td style={{ textAlign: 'center' }}>{p.tpFormula}</td>
                      <td style={{ textAlign: 'center' }}>{p.qtde}</td>
                      <td style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{MaskReal(p.valor)}</td>
                    </tr>
                  ))}

                  <tr>
                    <td colSpan="5" style={{ fontWeight: 600, textAlign: 'right' }}>
                      Valor total: {MaskReal(value.vlTotal)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </MainContent>
        </CardMain>
      </Main>
    </Wrapper>
  )
}
