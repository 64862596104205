import Lottie from 'react-lottie'
import { Card, CardBody } from 'reactstrap'
import animationData from './animationData.json'
import { Wrapper, TextMsg, BtnRastreamento } from './styles'
import { useState } from 'react'
import { useEffect } from 'react'

export default function CardMsgFinal({ value, change, step }) {
  const defaultOptions = { loop: false, autoplay: true, animationData: animationData }

  const [count, setCount] = useState(10)
  const [refTimer, setRefTimer] = useState(0)

  function startTimer() {
    try {
      if (refTimer != 0) clearInterval(refTimer)

      let newTimerId = setInterval(() => {
        setCount((count) => count - 1)
      }, 1000)

      setRefTimer(newTimerId)
    } catch {}
  }

  useEffect(() => {
    if (count === 0) onSend()
  }, [count])

  useEffect(() => {
    startTimer()
  }, [])

  const onSend = () => {
    window.location.reload()
  }
  return (
    <Wrapper>
      <Card className="main-card mb-1" style={{ width: '100%', height: '100%' }}>
        <CardBody style={{ width: '100%', height: '100%' }}>
          <Lottie options={defaultOptions} style={{ maxHeight: '200px', maxWidth: '300px' }} />
          <TextMsg>Pedido finalizado com sucesso</TextMsg>

          <BtnRastreamento onClick={onSend}>
            Acompanhar meu pedido <span>{count}</span>
          </BtnRastreamento>
        </CardBody>
      </Card>
    </Wrapper>
  )
}
