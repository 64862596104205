import styled from 'styled-components'

export const ColorlibStepIconRoot = styled.div`
  background-color: #ccc;

  color: white;
  width: 50;
  height: 50;
  display: flex;
  border-radius: 50%;
  justify-content: cente;
  align-items: center;
  /* ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, rgb(51, 102, 0) 0%, rgb(64, 128, 0) 50%, rgb(77, 153, 0) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, rgb(51, 102, 0) 0%, rgb(64, 128, 0) 50%, rgb(77, 153, 0) 100%)',
  }), */
`

export const TimeWrapper = styled.div`
  #tracking {
    background: #fff;
  }
  .tracking-detail {
    padding: 3rem 0;
  }
  #tracking {
    margin-bottom: 1rem;
  }
  [class*='tracking-status-'] p {
    margin: 0;
    font-size: 1.1rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }
  [class*='tracking-status-'] {
    padding: 1.6rem 0;
  }
  .tracking-list {
  }
  .tracking-item {
    border-left: 4px solid #00ba0d;
    position: relative;
    padding: 1rem 0.5rem 0.5rem 2rem;
    font-size: 0.9rem;
    margin-left: 2rem;
    min-height: 5rem;
  }
  .tracking-item:last-child {
    padding-bottom: 4rem;
  }
  .tracking-item .tracking-date {
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
      margin: 0 0 -40px 10px !important;
    }
  }
  .tracking-item .tracking-date span {
    color: #888;
    font-size: 85%;
    padding-left: 0.4rem;
  }
  .tracking-item .tracking-content {
    padding: 0.5rem 0.8rem;
    background-color: #f4f4f4;
    border-radius: 0.5rem;

    @media (max-width: 768px) {
      padding-left: 40px;
    }
  }
  .tracking-item .tracking-content span {
    display: block;
    color: #767676;
    font-size: 13px;
  }
  .tracking-icon {
    background-color: #00ba0d;
  }

  .tracking-item .tracking-icon {
    position: absolute;
    left: -0.7rem;
    width: 1.1rem;
    height: 1.1rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    background-color: #fff;
    color: #fff;
  }

  .tracking-item-pending {
    border-left: 4px solid #d6d6d6;
    position: relative;
    padding: 1rem 0.5rem 0.5rem 2rem;
    font-size: 0.9rem;
    margin-left: 2rem;
    min-height: 5rem;
  }
  .tracking-item-pending:last-child {
    padding-bottom: 2rem;
  }
  .tracking-item-pending .tracking-date {
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
      margin: 0 0 -32px 10px !important;
    }
  }
  .tracking-item-pending .tracking-date span {
    color: #888;
    font-size: 85%;
    padding-left: 0.4rem;
  }
  .tracking-item-pending .tracking-content {
    padding: 0.5rem 0.8rem;
    background-color: #f4f4f4;
    border-radius: 0.5rem;

    @media (max-width: 768px) {
      padding-left: 40px;
    }
  }
  .tracking-item-pending .tracking-content span {
    display: block;
    color: #767676;
    font-size: 13px;
  }
  .tracking-item-pending .tracking-icon {
    line-height: 0.9rem;
    position: absolute;
    left: -0.7rem;
    width: 1.2rem;
    height: 1.2rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    color: #d6d6d6;
  }
  .tracking-item-pending .tracking-content {
    font-weight: 600;
    font-size: 17px;
  }

  .tracking-item .tracking-icon.status-current {
    width: 1.9rem;
    height: 1.9rem;
    left: -1.1rem;
  }
  .tracking-item .tracking-icon.status-intransit {
    color: #00ba0d;
    font-size: 0.6rem;
  }
  .tracking-item .tracking-icon.status-current {
    color: #00ba0d;
    font-size: 0.6rem;
  }
  @media (min-width: 992px) {
    .tracking-item {
      margin-left: 4rem;
    }
    .tracking-item .tracking-date {
      position: absolute;
      left: -10rem;
      width: 7.5rem;
      text-align: right;
    }
    .tracking-item .tracking-date span {
      display: block;
    }
    .tracking-item .tracking-content {
      padding: 0;
      background-color: transparent;
    }

    .tracking-item-pending {
      margin-left: 4rem;
    }
    .tracking-item-pending .tracking-date {
      position: absolute;
      left: -10rem;
      width: 7.5rem;
      text-align: right;
    }
    .tracking-item-pending .tracking-date span {
      display: block;
    }
    .tracking-item-pending .tracking-content {
      padding: 0;
      background-color: transparent;
    }
  }

  .tracking-item .tracking-content {
    font-weight: 600;
    font-size: 17px;
  }

  .blinker {
    border: 7px solid #e9f8ea;
    animation: blink 1s;
    animation-iteration-count: infinite;
  }
  @keyframes blink {
    50% {
      border-color: #fff;
    }
  }
`
