import React, { useEffect } from 'react'
import { UsuarioGetAcessos } from '../../Service/ApiService'
import { putUserAccessMenu } from '../../Reducers/storageApp'

export default function Element({ onChange }) {
  async function User_LoadMenu() {
    const acessos = await UsuarioGetAcessos()

    console.log(acessos)
    let menu = [
      { icon: 'fa fa-list', label: 'Página Inicial', to: '/#/' },
      { icon: 'bi bi-basket2', label: 'Vendas', to: '/#/Sales' },
    ]

    if (acessos) {
      acessos.forEach((itemG) => {
        //Configurações
        if (itemG.grupo === 'Configurações') menu.push({ icon: 'fa fa-gear', label: 'Configurações', to: '/#/Settings' })
        //Planejamento
        else if (itemG.grupo === 'Planejamento') menu.push({ icon: 'fa fa-wpforms', label: 'Planejamento', to: '/#/Planning' })
        //Fórmulas
        else if (itemG.grupo === 'Fórmulas') {
          let menuGrupo = []
          itemG.itens.forEach((itemM) => {
            if (itemM.codigo === 'For_Query_Q') menuGrupo.push({ label: 'Acompanhamento', to: '/#/Formulas/Query' })
            else if (itemM.codigo === 'For_Step_AUp') menuGrupo.push({ label: 'Registro de etapas', to: '/#/Formulas/Step' })
            else if (itemM.codigo === 'For_Conference_Q') menuGrupo.push({ label: 'Correção', to: '/#/Formulas/Conference' })
            else if (itemM.codigo === 'For_Datasheet_Q') menuGrupo.push({ label: 'Ficha', to: '/#/Formulas/Datasheet' })
          })

          if (menuGrupo.length > 0) menu.push({ icon: 'fa fa-flask', label: 'Fórmulas', content: menuGrupo })
        }
        //Entregas
        else if (itemG.grupo === 'Entregas') {
          let menuGrupo = []
          itemG.itens.forEach((itemM) => {
            if (itemM.codigo === 'Del_Painel_Q') menuGrupo.push({ label: 'Painel', to: '/#/Delivery/Panel' })
            else if (itemM.codigo === 'Del_Divergences_Rep') menuGrupo.push({ label: 'Divergências', to: '/#/Delivery/Reports/Divergences' })
            else if (itemM.codigo === 'Del_Pending_Rep') menuGrupo.push({ label: 'Pendências', to: '/#/Delivery/Reports/Pending' })
          })

          if (menuGrupo.length > 0) menu.push({ icon: 'fa fa-truck', label: 'Entregas', content: menuGrupo })
        }
        //Indicadores
        else if (itemG.grupo === 'Indicadores') {
          let menuGrupo = []
          itemG.itens.forEach((itemM) => {
            if (itemM.codigo === 'Das_Managerial_Q') menuGrupo.push({ label: 'Gerenciais', to: '/#/Dashboard' })
            else if (itemM.codigo === 'Das_Financial_Q') menuGrupo.push({ label: 'Financeiros', to: '/#/Financial/Manager' })
            else if (itemM.codigo === 'Das_Store_Q') menuGrupo.push({ label: 'Loja', to: '/#/Financial/Branch' })
            else if (itemM.codigo === 'Das_Financial_Settings_AUp') menuGrupo.push({ label: 'Definição de Metas', to: '/#/Financial/Conf' })
          })

          if (menuGrupo.length > 0) menu.push({ icon: 'bi bi-bar-chart', label: 'Indicadores', content: menuGrupo })
        }

        //Metas - Colaboradores
        else if (itemG.grupo === 'Metas') {
          let menuGrupo = []
          itemG.itens.forEach((itemM) => {
            if (itemM.codigo === 'Das_Seller_Q') menuGrupo.push({ label: 'Minhas Metas', to: '/#/Financial/Seller' })
            else if (itemM.codigo === 'Das_Branch_Q') menuGrupo.push({ label: 'Metas da loja', to: '/#/Financial/Branch' })
          })

          if (menuGrupo.length > 0) menu.push({ icon: 'bi bi-bar-chart', label: 'Metas', content: menuGrupo })
        }
      })
    }

    await putUserAccessMenu(acessos, menu)
    onChange()
  }

  useEffect(() => {
    User_LoadMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}
